import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { cloneDeep } from 'lodash';
import { trackEvent } from '../../../utils/tracking'
import { DocumentUploadStatusEnum } from '../../../enums/common';

interface UploadingFile {
    fileName: string;
    fileId: string;
    status: DocumentUploadStatusEnum.UPLOADING | DocumentUploadStatusEnum.PROCESSING;
}

interface FetchKnowledgeGapReportStateI {
    uploadingFiles: UploadingFile[];
};

const initialState: FetchKnowledgeGapReportStateI = {
    uploadingFiles: []
};

export const fetchKnowledgeGapReportSlice = createSlice({
    name: 'fetch-knowledge-gap-report',
    initialState,
    reducers: {
        addUploadingFile: (state, action: PayloadAction<UploadingFile>) => {
            state.uploadingFiles = [action.payload, ...state.uploadingFiles]

            trackEvent('Upload Document', {
                fileId: action.payload.fileId,
                fileName: action.payload.fileName,
                status: action.payload.status
            });
        },
        updateUploadingFile: (state, action: PayloadAction<{ fileId: string; newFileId: string }>) => {
            const clonedUploadingFiles = cloneDeep(state.uploadingFiles);
            const indexOfFile: number = clonedUploadingFiles.findIndex(file => file.fileId === action.payload.fileId);
            clonedUploadingFiles[indexOfFile].fileId = action.payload.newFileId
            clonedUploadingFiles[indexOfFile].status = DocumentUploadStatusEnum.PROCESSING;
            state.uploadingFiles = clonedUploadingFiles;
        },
        removeUploadingFile: (state, action: PayloadAction<{ fileId: string }>) => {
            state.uploadingFiles = state.uploadingFiles.filter(file => file.fileId !== action.payload.fileId);
        },
        clearFetchKnowledgeGapReportState: (state) => {
            state.uploadingFiles = [];
        },
    },
})

export const {
    addUploadingFile,
    updateUploadingFile,
    removeUploadingFile,
    clearFetchKnowledgeGapReportState
} = fetchKnowledgeGapReportSlice.actions;

export default fetchKnowledgeGapReportSlice.reducer;