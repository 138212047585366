import React, { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from "../../hooks/reduxHooks"; // Import useAppDispatch hook
import { setCurrentUser, setFirstCollection } from '../../state/features/user/userSlice'; // Import the action to set the current user
import { getUserDetails } from '../../services/api/userAPI'; // Import your getUserDetails function
import { getAmplifySessionHeaders } from '../../services/auth-service';
import { setAllOrganizations, setSelectedOrganization } from '../../state/features/organization/organizationSlice';
import { getFirstCollection } from '../../services/api-service';
import { API_URL } from '../../config';

const CallbackPage = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch(); // Use the dispatch function from the Redux hook

    const fetchOrgsAndFirstCollection = async () => {
        const headers = await getAmplifySessionHeaders();
        try {
            const response = await fetch(`${API_URL}/users/orgs/`, {
                method: "GET",
                headers: headers
            });
            const data = await response.json();
            dispatch(setAllOrganizations(data));

            const currentOrg = data.find((org) => org.current);
            if (currentOrg) {
                dispatch(setSelectedOrganization(currentOrg))
            }

            const firstCollection = await getFirstCollection();
            dispatch(setFirstCollection(firstCollection));

        } catch (error) {
            console.error('Error fetching organizations', error);
        }
    };

    useEffect(() => {
        const handleAuth = async () => {
            try {
                // Ensure the user is authenticated
                await Auth.currentAuthenticatedUser({ bypassCache: true });

                // Use the getUserDetails function to fetch user details
                const userDetails = await getUserDetails();

                // Dispatch an action to update the user state in the Redux store
                dispatch(setCurrentUser(userDetails));
                await fetchOrgsAndFirstCollection();

                // Navigate to the correct route with organization_id and mode_id
                const orgId = userDetails.current_org.org_id;
                const modeId = 0;

                navigate(`/organization/${orgId}/fetch?mode=${modeId}`, { replace: true });
            } catch (error) {
                console.error("Error handling auth callback:", error);
                navigate('/login'); // Navigate to login on error
            }
        };

        handleAuth();
    }, [navigate, dispatch]);

    return (
        <div>
            Authenticating...
        </div>
    );
};

export default CallbackPage;
