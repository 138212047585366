export enum OrgTierEnum {
    BASIC = 0,
    INTERMEDIATE  = 1,
    ENTERPRISE = 2
}

export enum OrgUserRolesEnum {
    OWNER_OR_ADMIN = 0,
    COLLABORATOR = 1,
    MEMBER = 2
}